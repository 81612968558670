<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
    <v-dialog v-model="dialog_edit" max-width="400">
        <v-card>
            <v-card-title class="text-purple" style="font-weight: 300; font-size: 18px">
                แก้ไขการแจ้งเตือน
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
                    <v-row>
                        <v-col>
                            <span>เปิดใช้งาน : </span>
                            <v-switch style="margin-top: 0px" v-model="edit_alert.active" color="green" :value="1" hide-details></v-switch>

                        </v-col>
                        <v-col style="text-align: end;">
                            ลบ
                            <v-btn @click="delAlert" class="ma-2" x-small outlined fab color="red">
                                <v-icon>delete_forever</v-icon>
                            </v-btn>
                        </v-col>

                    </v-row>
                    <br>
                    <span>หัวข้อ</span>
                    <v-text-field v-model="edit_alert.note" :rules="nameRules" label="" dense outlined required hide-details></v-text-field>
                    <br>
                    <span>เลือกเวลาที่แจ้งเตือน</span>
                    <v-row>
                        <v-col cols="5">
                            <v-select :items="hour_list" v-model="edit_alert.alert_h" :rules="[(v) => !!v || 'โปรดเลือก']" label="HH" dense outlined required hide-details> </v-select>
                        </v-col>
                        <v-col cols="2" style="text-align: center;
    padding-top: 19px; "><b>:</b></v-col>
                        <v-col cols="5">
                            <v-select :items="minute_list" v-model="edit_alert.alert_m" :rules="[(v) => !!v || 'โปรดเลือก']" label="mm" dense outlined required hide-details></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col style="padding-top: 0px;">
                            <span>วันที่แจ้งเตือน</span>
                            <v-divider></v-divider>
                            <v-checkbox v-model="edit_alert.all" label="ทุกวัน" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="edit_alert.mon" label="จันทร์" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="edit_alert.tue" label="อังคาร" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="edit_alert.wed" label="พุธ" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="edit_alert.thu" label="พฤหัสบดี" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="edit_alert.fri" label="ศุกร์" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="edit_alert.sat" label="เสาร์" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="edit_alert.sun" label="อาทิตย์" color="primary" dense value="" hide-details></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple" text @click="dialog_edit = false">ยกเลิก</v-btn>
                <v-btn color="purple" text @click="validate_edit">ตกลง</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_new" max-width="400">
        <v-card>

            <v-card-title class="text-purple" style="font-weight: 300; font-size: 18px">
                เพิ่มการแจ้งเตือน
            </v-card-title>
            <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation style="width: 100%">

                    <span>เปิดใช้งาน : </span>
                    <v-switch style="margin-top: 0px" v-model="active" color="green" :value="1" required hide-details></v-switch>
                    <br>
                    <span>หัวข้อ</span>
                    <v-text-field v-model="alert_name" :rules="nameRules" label="" dense outlined required hide-details></v-text-field>
                    <br>
                    <span>เลือกเวลาที่แจ้งเตือน</span>
                    <v-row>
                        <v-col cols="5">
                            <v-select :items="hour_list" v-model="alert_h" :rules="[(v) => !!v || 'โปรดเลือก']" label="HH" dense outlined required hide-details> </v-select>
                        </v-col>
                        <v-col cols="2" style="text-align: center;
    padding-top: 19px; "><b>:</b></v-col>
                        <v-col cols="5">
                            <v-select :items="minute_list" v-model="alert_m" :rules="[(v) => !!v || 'โปรดเลือก']" label="mm" dense outlined required hide-details></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col style="padding-top: 0px;">
                            <span>วันที่แจ้งเตือน</span>
                            <v-divider></v-divider>
                            <v-checkbox v-model="all" label="ทุกวัน" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="mon" label="จันทร์" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="tue" label="อังคาร" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="wed" label="พุธ" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="thu" label="พฤหัสบดี" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="fri" label="ศุกร์" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="sat" label="เสาร์" color="primary" dense value="" hide-details></v-checkbox>
                            <v-checkbox v-model="sun" label="อาทิตย์" color="primary" dense value="" hide-details></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple" text @click="dialog_new=false">ยกเลิก</v-btn>
                <v-btn color="purple" text @click="validate">ตกลง</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-container>
        <v-row class="align-center d-flex h-0 pb-20">
            <v-col xl="3" lg="4" sm="8" class="mx-auto">
                <checkmong-home-bar title="เตือนกินยา"></checkmong-home-bar>
                <br />
                <span class="text-purple">รายการแจ้งเตือน</span>
                <v-divider></v-divider>
                <br />
                <v-row class="d-flex">
                    <v-col cols="12">
                        <v-row class="d-flex">
                            <v-col v-for="(alert, index) in alert_list" cols="12" style="margin-bottom: -12px">
                                <v-card elevation="1" style="height: 70px" @click.passive="edit_data(alert)">
                                    <v-row class="d-flex" style="margin: 7px">
                                        <v-col cols="2" style="
                          padding-bottom: 0;
                          padding-top: 0;
                          align-self: center;
                        ">
                                            <v-img src="@/assets/img/checkmong/drug.png" width="42px"></v-img>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-row>
                                                <v-col cols="12" style="padding-bottom: 0; padding-top: 0">
                                                    {{ alert.note }}
                                                </v-col>
                                                <v-col cols="6" style="padding-bottom: 0; padding-top: 0">
                                                    <span style="font-size: 25px">{{alert.alert_h}}:{{alert.alert_m}}</span>
                                                </v-col>
                                                <v-col cols="6" style="padding-bottom: 0">
                                                    <span v-if="alert.all" style="font-size: 12px">
                                                        {{alert.all ? 'ทุกวัน' : ''}}
                                                    </span>
                                                    <span v-else style="font-size: 12px">
                                                        {{alert.mon ? 'จ' : ''}}
                                                        {{alert.tue ? 'อ' : ''}}
                                                        {{alert.wed ? 'พ' : ''}}
                                                        {{alert.thu ? 'พฤ' : ''}}
                                                        {{alert.fri ? 'ศ' : ''}}
                                                        {{alert.sat ? 'ส' : ''}}
                                                        {{alert.sun ? 'อา' : ''}}
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="2" style="padding-bottom: 0">
                                            <v-switch style="margin-top: 0px" v-model="alert.active" color="green" :value="alert.active" hide-details disabled></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px">
            <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <v-btn style="width: 250px" color="bg-primary text-white" @click="dialog_new=true" class="border-radius-xl">
                <v-icon left> control_point </v-icon>
                เพิ่มการแจ้งเตือนกินยา
            </v-btn>
        </v-row>

        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-center" justify="center">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <v-btn style="width: 250px" color="bg-primary text-white" @click="dialog_new=true" class="border-radius-xl">
                    <v-icon left> control_point </v-icon>
                    เพิ่มการแจ้งเตือนกินยา
                </v-btn>
            </v-row>
        </v-footer>
    </v-container>
</div>
</template>

<script >
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
import WaitingModal from "@/components/WaitingModal.vue";
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
export default {
    name: "medicine_alert",
    components: {
        CheckmongHomeBar,
        WaitingModal,
    },
    data: () => ({
        alert_h:'',
        alert_m:'',
        active_waiting: false,
        status: '',
        alert_name: '',
        day: [],
        day2: [],
        edit_alert: {},
        hour_picked: '00',
        minute_picked: '00',
        hour_list: [],
        minute_list: [],
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        sun: 0,
        all: 0,
        title: "",
        time: "",
        attrs: "",
        on: "",
        selected_row: "",
        dialog_edit: false,
        dialog_new: false,
        arrayEvents: null,
        active: 1,
        valid: true,

        nameRules: [
            (v) => !!v || "โปรดกรอกข้อมูล",

        ],
        alert_list: [],
    }),


    mounted() {

        this.create_time_pick()
        this.get_alert_record()
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
        dialog_new() {
            // this.$refs.form.reset()
            // //console.log('clear_new')
        },
        dialog_edit() {

            // //console.log('clear_edit')
        },
        alert_list(){
            
        }
    },
    updated() { },
    methods: {
        getDate(val) {
            const new_d_list = val.split(",").sort()
            let text = "";
            let d = "";
            for (let x in new_d_list) {
                if (new_d_list[x] == '0') {
                    text = 'ทุกวัน';
                    break
                }
                if (new_d_list[x] == '1') {
                    d = 'จ '
                }
                if (new_d_list[x] == '2') {
                    d = 'อ '
                }
                if (new_d_list[x] == '3') {
                    d = 'พ '
                }
                if (new_d_list[x] == '4') {
                    d = 'พฤ '
                }
                if (new_d_list[x] == '5') {
                    d = 'ศ '
                }
                if (new_d_list[x] == '6') {
                    d = 'ส '
                }
                if (new_d_list[x] == '7') {
                    d = 'อา '
                }
                text += d;
            }
            return text
        },

        async delAlert() {

            this.active_waiting = true
            this.title = 'กำลังลบข้อมูล',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/del_alert_record', {
                "id": this.edit_alert.id
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.active_waiting = false
                    this.dialog_edit = false
                    this.get_alert_record()
                   
                    // this.$store.commit('setUserInfo',response.data);
                    // this.district = response.data.district
                    // this.subdistrict_list = response.data.location_list
                    // this.location_id = response.data.location_list
                } else {
                    this.active_waiting = false

                }

            })
        },
        update_status_wait(val) {
            this.active_waiting = val
        },
        async get_alert_record() {
            this.active_waiting = true
            this.title = 'กำลังอัพเดทข้อมูล',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/get_alert_record', {
                "user_id": this.$store.state.login_detail.user_id
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log(response.data)
                if (response.data) {
                    this.active_waiting = false
                    // this.$store.commit('setUserInfo',response.data);
                    this.alert_list = response.data.alert_list
                    this.dialog_edit = false
                    //console.log('-xxxxxxxxxxxx')
                    //console.log(this.alert_list)
                    // this.district = response.data.district
                    // this.subdistrict_list = response.data.location_list
                    // this.location_id = response.data.location_list
                } else {
                    this.active_waiting = false
                    this.alert_list = []
                }

            })

        },
        async submitVal() {

            this.dialog_new = false
            this.active_waiting = true
            this.title = 'กำลังเพิ่มข้อมูล',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/add_alert_record', {
                "user_id": this.$store.state.login_detail.user_id,
                "note": this.alert_name,
                "alert_time": this.hour_picked + ':' + this.minute_picked,
                "alert_day": String(this.day),
                "active": this.active,
                "alert_h": this.alert_h,
                "alert_m": this.alert_m,
                "mon": this.mon,
                "tue": this.tue,
                "wed": this.wed,
                "thu": this.thu,
                "fri": this.fri,
                "sat": this.sat,
                "sun": this.sun,
                "all": this.all,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.title = 'เพิ่มข้อมูลสำเร็จ';
                    this.status = 'done'
                    setTimeout(() => {
                        this.active_waiting = false
                    }, 2000)
                    this.get_alert_record()
                    this.$refs.form.reset()
                } else {
                    this.active_waiting = false
                    this.title = 'ระบบขัดข้อง',
                        this.status = 'fail'
                }

            })

        },
        async editVal() {

            this.dialog_new = false
            this.active_waiting = true
            this.title = 'กำลังเพิ่มข้อมูล',
                this.status = 'loading'
            const data = {
                "id": this.edit_alert.id,
                "user_id": this.$store.state.login_detail.user_id,
                "note": this.edit_alert.note,
                "alert_time": this.hour_picked + ':' + this.minute_picked,
                "alert_day": String(this.day2),
                "active": this.edit_alert.active,
                "alert_h": this.edit_alert.alert_h,
                "alert_m": this.edit_alert.alert_m,
                "mon": this.edit_alert.mon,
                "tue": this.edit_alert.tue,
                "wed": this.edit_alert.wed,
                "thu": this.edit_alert.thu,
                "fri": this.edit_alert.fri,
                "sat": this.edit_alert.sat,
                "sun": this.edit_alert.sun,
                "all": this.edit_alert.all,

            }
            //console.log(data)
            await Vue.axios.post(APIURL + '/update_alert_record', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log(response)
                if (response.data) {
                    this.title = 'เพิ่มข้อมูลสำเร็จ';
                    this.status = 'done'
                    setTimeout(() => {
                        this.active_waiting = false
                    }, 2000)
                    this.dialog_new = false
                    this.get_alert_record()
                    this.$refs.form.reset()

                } else {
                    this.active_waiting = false
                    this.title = 'ระบบขัดข้อง',
                        this.status = 'fail'
                }

            })

        },
        handleClick(value) {
            this.selected_row = value;
            this.dialog_edit = true;
        },
        edit_data(alert) {
            this.edit_alert = alert;
            this.dialog_edit = true;
            //console.log(this.edit_alert)
        },
        create_time_pick() {
            var h = [];
            for (var i = 0; i <= 23; i++) {
                h.push(String(i).padStart(2, '0'));
            }
            this.hour_list = h

            var m = [];
            for (var j = 0; j <= 59; j++) {
                m.push(String(j).padStart(2, '0'));
            }
            this.minute_list = m

            //console.log(this.hour_list)
            //console.log(this.minute_list)
        },

        save(date) {
            this.$refs.menu.save(date)
        },

        validate() {
            const res = this.$refs.form.validate();
            //console.log(res)
            if (res) {
                this.submitVal()
            }
            // 
        },
        validate_edit() {
            const res = this.$refs.form.validate();
            //console.log(res)
            if (res) {
                this.editVal()
            }
        }

    },
};
</script>

<style scoped>

.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}




.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 0px;
}
/*
.v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: white;
}*/
</style>
